import React, { useEffect, Fragment } from "react";
import { navigate } from "gatsby-plugin-intl";

import * as constants from "../../app.constant";

const PagePurchase = () => {
    useEffect(() => {
        localStorage.clear();
        navigate(constants.ROUTES.PLANS);
    }, []);

    return (
        <Fragment>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: 30,
                margin: 0,
                width: "100%",
                height: "100vh",
                textAlign: "center",
                visibility: "hidden",
            }}>
                <h1>Samsung Care+</h1>
                <h2>Taiwan<br />(Purchase)</h2>
            </div>
        </Fragment>
    );
};

export default PagePurchase;
